import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'
import Image, { IMAGES } from '../../components/image'
import DetailText from '../../components/detail-text'
import SEO from '../../components/seo'

const JewelTonesBlastPage = ({ location }) => (
  <Layout location={location} crumbLabel="Jewel Tones Blast">
    <SEO title="Home" />
    <div
      className="
      grid
      pb-4 sm:pb-0
      sm:col-span-6 sm:grid-cols-2 sm:gap-4
      md:justify-i-end
      "
    >
      <div className="" />
      <div className="md:max-w-xs w-full">
        <Image filename={IMAGES.jewelTonesBlastIcon} />
      </div>
    </div>
    <div className="
      grid
      sm:col-span-6 sm:grid-cols-6 sm:gap-4
      md:col-span-6 md:grid-cols-2 md:gap-4
      "
    >
      <div className="sm:col-span-5 md:col-span-1">
        <DetailText
          header="Jewel Tones Blast"
          body="This is a peaceful, free, match-three style game available on iOS and Android. Sit back and relax with mellow colors, soothing sounds, and four difficulty levels, each with its own high score tracker! A game you can play on your time, and your terms."
        />
        <DetailText
          header="Download"
          body={(
            <>
              <p>
                <a
                  href="https://apps.apple.com/us/app/jewel-tones-blast/id1500832585"
                  className="max-w-badge block"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image alt="Download Free from Apple App Store" filename={IMAGES.appleAppStoreBadge} />
                </a>
              </p>
              <p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sleepyfox.jeweltonesblast"
                  className="max-w-badge block"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image alt="Download Free from Google Play Store" filename={IMAGES.googlePlayStoreBadge} />
                </a>
              </p>
            </>
          )}
        />
        <DetailText
          header="Other Details"
          body={<Link to="/jewel-tones-blast/privacy-policy">Privacy Policy</Link>}
        />
      </div>
    </div>
  </Layout>
)

JewelTonesBlastPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default JewelTonesBlastPage
